import React, { useState } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Container from "../components/active-plugin-count/Container";
import "../../markup/assets/css/bootstrap/bootstrap-grid.min.css";
import "../../markup/assets/css/icon/style.css";
import "../../markup/assets/css/style.min.css";

const IndexPage = () => {
  const [featureImage, setFeatureImage] = useState(
    "https://assets.wpinsight.com/feature-thumb.png"
  );
  return (
    <Layout>
      <SEO title="Track your plugins - WP Insight" pathName="/" />
      <div className="wpi-banner__bg section--relative banner-section">
        <div className="shape__layer">
          <img
            src="https://assets.wpinsight.com/banner-bottom-shape.svg"
            className="bottom__bg--shape"
            alt=""
          />
          <img
            src="https://assets.wpinsight.com/dot.png"
            className="dot-1"
            alt=""
          />
          <img
            src="https://assets.wpinsight.com/dot.png"
            className="dot-2"
            alt=""
          />
        </div>
        <div className="section__wrap">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="wpi-banner">
                  <h3>Take Your WordPress Business To The Next Level With</h3>
                  <h1>
                    Advanced Analytics & Insights{" "}
                    <span>for WordPress Plugin & Theme</span>
                  </h1>
                  <div className="button-group">
                    <a
                      href="https://dashboard.wpinsight.com/auth/registration"
                      target="_blank"
                      rel="noreferrer"
                      className="button secondary__button"
                    >
                      Get it for free
                    </a>
                    {/* <a href="#" className="button primary__button hover__highlight">
                      Check live demo
                    </a> */}
                  </div>
                  <div className="banner__thumb">
                    <div className="layer__one">
                      <object
                        type="image/svg+xml"
                        data="https://assets.wpinsight.com/dashboard.svg"
                      ></object>
                    </div>
                    <div className="layer__two">
                      <object
                        type="image/svg+xml"
                        data="https://assets.wpinsight.com/mailchimp.svg"
                      ></object>
                    </div>
                    <div className="layer__three">
                      <object
                        type="image/svg+xml"
                        data="https://assets.wpinsight.com/php.svg"
                      ></object>
                    </div>
                    <div className="layer__four">
                      <img src="https://assets.wpinsight.com/active-user.gif" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ overflow: "hidden" }}
        className="background__primary section--relative about-section pt110"
      >
        <div className="shape__layer">
          <img
            src="https://assets.wpinsight.com/service-bottom-shape.svg"
            className="bottom__bg--shape"
            alt=""
          />
          <img
            src="https://assets.wpinsight.com/about-wave.png"
            className="about__wave--shape"
            alt=""
          ></img>
          <img
            src="https://assets.wpinsight.com/dot-white.png"
            className="dot-1"
            alt=""
          />
          <img
            src="https://assets.wpinsight.com/circle.png"
            className="circle"
            alt=""
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="section__header">
                <p>WPInsight for WordPress</p>
                <h2>Measure Everything & Make Data-Driven Decisions</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="about__thumb">
                <object
                  type="image/svg+xml"
                  data="https://assets.wpinsight.com/status.svg"
                ></object>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt150 pb50 pt-md-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col col-lg-6 order-lg-2">
              <div className="wpi-feature padding__left__70">
                <h3>Getting started is easiest within 2 minutes</h3>
                <h2>
                  Get Insights Now, <span>Build Beautiful Visualizations</span>
                </h2>
                <div className="feature__tab__control">
                  <button
                    onClick={() =>
                      setFeatureImage(
                        "https://assets.wpinsight.com/feature-thumb.png"
                      )
                    }
                    className={`feature__tab__control__item ${
                      featureImage ===
                      "https://assets.wpinsight.com/feature-thumb.png"
                        ? "active"
                        : ""
                    }`}
                  >
                    <span className="icon">
                      <span className="wpi wpi-Arrow"></span>
                    </span>{" "}
                    Add Basic Info
                  </button>
                  <button
                    onClick={() =>
                      setFeatureImage(
                        "https://assets.wpinsight.com/feature-thumb-2.png"
                      )
                    }
                    className={`feature__tab__control__item ${
                      featureImage ===
                      "https://assets.wpinsight.com/feature-thumb-2.png"
                        ? "active"
                        : ""
                    }`}
                  >
                    <span className="icon">
                      <span className="wpi wpi-Arrow"></span>
                    </span>{" "}
                    Include Meta Info
                  </button>
                  <button
                    onClick={() =>
                      setFeatureImage(
                        "https://assets.wpinsight.com/feature-thumb-3.png"
                      )
                    }
                    className={`feature__tab__control__item ${
                      featureImage ===
                      "https://assets.wpinsight.com/feature-thumb-3.png"
                        ? "active"
                        : ""
                    }`}
                  >
                    <span className="icon">
                      <span className="wpi wpi-Arrow"></span>
                    </span>{" "}
                    Connect Marketing Info
                  </button>
                </div>
              </div>
            </div>

            <div className="col-lg-6 order-lg-1">
              <div className="feature__thumb">
                <img src={featureImage} alt="feature thumb" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt300 pt-md-150 background__whiteyTightee section--relative service-section">
        <div className="shape__layer">
          <img
            src="https://assets.wpinsight.com/top--shape.svg"
            className="top--shape"
            alt=""
          />
          <img
            src="https://assets.wpinsight.com/big-dot.png"
            className="big-dot"
            alt=""
          />
          <img
            src="https://assets.wpinsight.com/circle-2.png"
            className="circle-2"
            alt=""
          />
          <img
            src="https://assets.wpinsight.com/dot-blue.png"
            className="dot-blue"
            alt=""
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="section__header secondary__color">
                <h2 className="color__dark">
                  Built for YOU, Inside WordPress Ecosystem
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="wpi-service__block card-columns">
                <div className="service--wrap first-child">
                  <div className="service">
                    <div className="service__icon">
                      <img
                        src="https://assets.wpinsight.com/icon-1.png"
                        alt="Service icon"
                      />
                    </div>
                    <h3>WordPress Plugin Developer</h3>
                    <p>
                      Advanced Analytics & Insights for WordPress Plugin & Theme
                    </p>
                  </div>
                </div>
                <div className="service--wrap">
                  <div className="service">
                    <div className="service__icon">
                      <img
                        src="https://assets.wpinsight.com/icon-2.png"
                        alt="Service icon"
                      />
                    </div>
                    <h3>WordPress Theme Developer</h3>
                    <p>
                      Advanced Analytics & Insights for WordPress Plugin & Theme
                    </p>
                  </div>
                </div>
                <div className="service--wrap">
                  <div className="service">
                    <div className="service__icon">
                      <img
                        src="https://assets.wpinsight.com/icon-4.png"
                        alt="Service icon"
                      />
                    </div>
                    <h3>Marketing Team</h3>
                    <p>
                      Advanced Analytics & Insights for WordPress Plugin & Theme
                    </p>
                  </div>
                </div>
                <div className="service--wrap third-child">
                  <div className="service">
                    <div className="service__icon">
                      <img
                        src="https://assets.wpinsight.com/icon-3.png"
                        alt="Service icon"
                      />
                    </div>
                    <h3>CEO/Founder</h3>
                    <p>
                      Advanced Analytics & Insights for WordPress Plugin & Theme
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Container />
      <div className="pt70 pb110 pt-md-0 background__whiteyTightee section--relative cta-section">
        <div className="shape__layer">
          <img
            src="https://assets.wpinsight.com/cta-bottom-shape.png"
            className="bottom-bg-shape"
            alt=""
          />
          <img
            src="https://assets.wpinsight.com/cta-wave.png"
            className="cta-wave"
            alt=""
          />
          <img
            src="https://assets.wpinsight.com/dot.png"
            className="dot"
            alt=""
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="wpi-call-to-action background__white">
                <div className="call-to-action-content">
                  <h2>
                    100% Risk Free 14 <br /> Days Money Back Guarantee!
                  </h2>
                </div>
                <div className="button-group">
                  <a
                    href="https://dashboard.wpinsight.com/auth/registration"
                    target="_blank"
                    rel="noreferrer"
                    className="button secondary__button"
                  >
                    Get Started
                  </a>
                  {/* <a href="#" className="button primary__button hover__highlight">
                    Check live demo
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
