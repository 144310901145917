import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import PluginDetails from "./PluginDetails";
import ShowData from "./ShowData";

function Container() {
  const [selectedDate, setSelectedDate] = useState(new Date("2020-05-12"));
  const [count, setCount] = useState(5000000);
  const [slug, setSlug] = useState("elementor");
  const [data, setData] = useState(undefined);
  const [searchLoading, setSearchLoading] = useState(false);
  const [resultLoading, setResultLoading] = useState(false);
  const [searchResult, setSearchResult] = useState(undefined);

  const handleSlugChange = (value) => {
    if (value !== slug) {
      setSlug(value);
      setData(undefined);
    }
  };

  const changeDate = (date) => {
    if (selectedDate !== date) {
      setSelectedDate(date);
      setData(undefined);
    }
  };

  const handleCount = (e) => {
    if (e.target.value !== count) {
      setCount(e.target.value);
      setData(undefined);
    }
  };

  const handleData = (data) => {
    setData(data);
    setResultLoading(false);
  };

  const handleResult = (result) => {
    setSearchResult(result);
    setSearchLoading(false);
  };

  const showToast = () => {
    setSearchLoading(false);
    setResultLoading(false);
  };

  const handleSearch = () => {
    setSearchLoading(true);
    axios
      .get(
        `https://us-central1-wpinsight.cloudfunctions.net/fetchPluginsFromSlug?search=${slug}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json"
          }
        }
      )
      .then(
        (response) =>
          response &&
          response.data &&
          response.data.plugins &&
          handleResult(response.data.plugins)
      )
      .catch((e) => showToast());
  };

  const handleSubmit = () => {
    if (slug === "") {
      return;
    }
    if (count < 1) {
      return;
    }

    setResultLoading(undefined);

    const initialDay = moment(selectedDate);
    const today = moment();

    const diff = today.diff(initialDay, "days");
    if (diff < 1) {
      return;
    }

    setResultLoading(true);

    axios
      .get(
        `https://us-central1-wpinsight.cloudfunctions.net/fetchGrowthFromSlug?slug=${slug}&diff=${diff}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json"
          }
        }
      )
      .then(
        (response) => response && response.data && handleData(response.data)
      )
      .catch((e) => showToast());
  };

  return (
    <div>
      <PluginDetails
        slug={slug}
        changeDate={changeDate}
        searchLoading={searchLoading}
        resultLoading={resultLoading}
        selectedDate={selectedDate}
        count={count}
        handleCount={handleCount}
        handleSubmit={handleSubmit}
        handleSearch={handleSearch}
        searchResult={searchResult}
        handleSlugChange={handleSlugChange}
      />
      <ShowData
        data={data}
        selectedDate={selectedDate}
        count={count}
        loading={resultLoading}
      />
    </div>
  );
}

export default Container;
