import React from "react";

const ShowData = ({ data, selectedDate, count }) => {
  let calculatedNumber = Number(count);
  let weeklyProgress = [];
  if (data === undefined || !selectedDate || count < 1) {
    return <div></div>;
  }
  // eslint-disable-next-line
  Object.entries(data).map(([key, value]) => {
    calculatedNumber =
      calculatedNumber + (calculatedNumber * Number(value)) / 100;
    weeklyProgress.push({ date: key, change: value, count: calculatedNumber });
  });
  return (
    <>
    <div className="background__whiteyTightee pb110">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-8 offset-xl-3 offset-lg-2">
            <div className="active__plugin__wrap">
              <div className="plugin__search__result">
                <div>
                  {data && <p>Total active installs: {parseInt(calculatedNumber)}</p>}
                </div>
                <div>
                  {weeklyProgress && (
                    <table>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Weekly Change</th>
                          <th>Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {weeklyProgress &&
                          weeklyProgress.map((item, index) => (
                            <tr key={index}>
                              <td>{item.date}</td>
                              <td>{item.change}</td>
                              <td>{parseInt(item.count)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ShowData;