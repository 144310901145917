import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./active-plugin.css";

export default function PluginDetails({
  slug,
  selectedDate,
  changeDate,
  count,
  searchLoading,
  resultLoading,
  handleCount,
  handleSubmit,
  handleSearch,
  searchResult,
  handleSlugChange
}) {
  return (
    <div className="active__plugin background__whiteyTightee pb50">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-8 offset-xl-3 offset-lg-2">
            <div className="active__plugin__wrap">
              <div className="active__plugin__form">
                <div className="form-group">
                  <label>Plugin Slug: </label>
                  <input
                    className="form-control"
                    type="text"
                    value={slug}
                    onChange={(e) => handleSlugChange(e.target.value)}
                  />
                </div>
                <div>
                  <button
                    className="button primary__button"
                    onClick={() => handleSearch()}
                  >
                    {searchLoading ? "Loading ..." : "Search Plugin"}
                  </button>
                </div>
              </div>

              {searchResult && (
                <div className="plugin__search__result">
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th className="action">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {searchResult.map((item, index) => (
                        <tr key={index}>
                          <td>{item && item.name}</td>
                          <td className="action">
                            <button 
                              className="button button--sm primary__button hover__highlight"
                              onClick={() =>
                                handleSlugChange(item && item.slug)
                              }
                            >
                              Set Slug
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Latest Count Change Date:</label>{" "}
                    <DatePicker selected={selectedDate} onChange={changeDate} />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Latest Count:</label>{" "}
                    <input
                      className="form-control"
                      type="number"
                      value={count}
                      onChange={handleCount}
                    />
                  </div>
                </div>
              </div>
              <button
                className="button primary__button"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {resultLoading ? "Loading ..." : "Calculate"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
